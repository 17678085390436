import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { ImageEditorComponent as EditorComp } from '@syncfusion/ej2-react-image-editor';
import { blobToBase64 } from '../../utils/etc';

const SpinnerWrapper = styled.div`
  padding: 130px 50px;
  text-align: center;
`;

const toolbarItems = [
  'Crop',
  'Straightening',
  'Annotate',
  'Transform',
  'Finetunes',
  'Filters',
  'Frame',
  'Resize',
  'ZoomIn',
  'ZoomOut',
  'Undo',
  'Redo',
  'Reset',
];

const ImageEditorComponent = ({ image, editorRef, loading }) => {
  useEffect(() => {
    if (image?.url && editorRef.current) {
      (async () => {
        try {
          await getFile(editorRef.current);
        } catch (error) {
          console.error('Error loading image in useEffect:', {
            url: image?.url,
            error: error.message,
            stack: error.stack,
          });
        }
      })();
    }
    // eslint-disable-next-line
  }, [image]);
  const getFile = async currentEditor => {
    try {
      const blob = await fetch(image.url).then(response => response.blob());
      const baseFile = await blobToBase64(blob);
      console.log('currentEditor', currentEditor);
      await currentEditor.open(baseFile);
      await setShapesStrokes();
    } catch (e) {
      console.error('Failed to load image in ImageEditor', {
        url: image.url,
        error: e.message,
        stack: e.stack,
      });
    }
  };

  const setShapesStrokes = () => {
    editorRef.current.updateShape({
      type: 'Rectangle',
      strokeWidth: 14,
    });
    editorRef.current.updateShape({
      type: 'Ellipse',
      strokeWidth: 14,
    });
    editorRef.current.updateShape({
      type: 'Line',
      strokeWidth: 14,
    });
    editorRef.current.updateShape({
      type: 'Arrow',
      strokeWidth: 14,
    });
    editorRef.current.updateShape({
      type: 'Path',
      strokeWidth: 14,
    });
    editorRef.current.updateShape({
      type: 'Text',
      strokeWidth: 14,
    });
    editorRef.current.updateShape({
      type: 'FreehandDraw',
      strokeWidth: 14,
    });
    editorRef.current.updateShape({
      type: 'Image',
      strokeWidth: 14,
    });
  };

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spin size='large' />
      </SpinnerWrapper>
    );
  }

  return <EditorComp ref={editorRef} toolbar={toolbarItems} />;
};

export default React.memo(ImageEditorComponent);
